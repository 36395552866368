































import { Component, Vue } from "vue-property-decorator";
@Component({})
export default class Time extends Vue {
  date: any = {};
  item: any = {};
  timeBeforShow = true;
  get rem() {
    const rem = this.$vuetify.breakpoint.width / 100 + "rem";
    return rem;
  }
  handleResize(e: any) {
    console.log("Bowen: Time -> handleResize -> e", e);
  }
  getDate() {
    const date: Date = new Date();
    this.date = {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    };
    this.item = {
      hours: date.getHours(),
      minutes: date.getMinutes(),
    };
  }
  handleShow() {
    this.timeBeforShow = !this.timeBeforShow;
  }
  created() {
    this.getDate();
    setInterval(this.getDate, 1000);
    setInterval(this.handleShow, 1000);
  }
  mounted() {
    (window as any).tt = this;
  }
}
